<template>
  <div>
    <VasionSchedulersPage title="Document Template" />
  </div>
</template>

<script>
import VasionSchedulersPage from '@/components/shared/VasionSchedulersPage.vue';

export default {
  name: 'DocumentTemplate',
  components: {
    VasionSchedulersPage,
  },
  data: function () {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
